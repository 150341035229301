import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const headerLinks = ({ text, location }) => {
  const link = text.replace(/\s/g, "_");
  const active = ((link.includes("home") && location.pathname === "/") || location.pathname.includes(link));
  const isActive = active ? "text-green-500 border-green-500 border-b-4" : "text-white";
  const homeLink = link.includes("home") ? "" : link;

  return (
    <Link to={`/${homeLink}`} className={`py-4 px-2 ${isActive} font-semibold hover:text-green-700 transition duration-300 justify-center items-center`}>
      <button href="#">
        {text.replace(/(^\w{1})|(\s+\w{1}|_)/g, (letter) => letter.toUpperCase())}
      </button>
    </Link>
  );
};

headerLinks.propTypes = {
  text: PropTypes.string,
  active: PropTypes.bool
};

headerLinks.defaultProps = {
  text: "",
  active: false
};

export default headerLinks;
