import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const footerLinks = ({ text, location }) => {
  const link = text.replace(/\s/g, "_");
  const active = location.pathname.includes(link);
  const isActive = active ? "text-green-500 border-green-500 border-b-4" : "hover:underline";
  const homeLink = link.includes("home") ? "" : link;

  return (
    <Link
      to={`/${homeLink}`}
      className={"block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline hover:text-green-500 transition duration-300"}
    >
      <button href="#" className={`${isActive} break-words`}>
        {text.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())}
      </button>
    </Link>
  );
};

footerLinks.propTypes = {
  text: PropTypes.string,
  active: PropTypes.bool
};

footerLinks.defaultProps = {
  text: "",
  active: false
};

export default footerLinks;
