import * as React from "react";
import PropTypes from "prop-types";
import { FooterLinks, TitleLinks } from "../links";

const Footer = ({ title, companyNumber, companyEmail, location }) => (
  <footer className="bg-white dark:bg-gray-800">
    <div className="container px-6 py-4 mx-auto">
      <div className="lg:flex">
        <div className="w-full -mx-6 lg:w-2/5">
          <div className="px-6">
            <TitleLinks className={"py-1"} text={title} location={location} />
            <p className="max-w-md mt-4 text-gray-500 dark:text-gray-400 break-words">Working to build the home of your dreams.</p>
            <div className="flex mt-4 -mx-2">
              <a target="_blank" rel="noopener noreferrer" href="https://www.tiktok.com/@paraisolandscaping">
                <button className="mx-2 text-gray-700 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400" aria-label="Linkden">
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className={"w-4 h-4 fill-current"} viewBox="0 0 24 24">
                    <path d="M 6 3 C 4.3550302 3 3 4.3550302 3 6 L 3 18 C 3 19.64497 4.3550302 21 6 21 L 18 21 C 19.64497 21 21 19.64497 21 18 L 21 6 C 21 4.3550302 19.64497 3 18 3 L 6 3 z M 6 5 L 18 5 C 18.56503 5 19 5.4349698 19 6 L 19 18 C 19 18.56503 18.56503 19 18 19 L 6 19 C 5.4349698 19 5 18.56503 5 18 L 5 6 C 5 5.4349698 5.4349698 5 6 5 z M 12 7 L 12 14 C 12 14.56503 11.56503 15 11 15 C 10.43497 15 10 14.56503 10 14 C 10 13.43497 10.43497 13 11 13 L 11 11 C 9.3550302 11 8 12.35503 8 14 C 8 15.64497 9.3550302 17 11 17 C 12.64497 17 14 15.64497 14 14 L 14 10.232422 C 14.616148 10.671342 15.259118 11 16 11 L 16 9 C 15.952667 9 15.262674 8.7809373 14.78125 8.3613281 C 14.299826 7.941719 14 7.4149911 14 7 L 12 7 z"></path>
                  </svg>
                </button>
              </a>
              <a target="_blank" rel="noopener noreferrer" href={"https://www.facebook.com/paraisolandscaping.pnw"}>
                <button className="mx-2 text-gray-700 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400" aria-label="Facebook">
                  <svg className="w-4 h-4 fill-current" viewBox="0 0 512 512">
                    <path d="M455.27,32H56.73A24.74,24.74,0,0,0,32,56.73V455.27A24.74,24.74,0,0,0,56.73,480H256V304H202.45V240H256V189c0-57.86,40.13-89.36,91.82-89.36,24.73,0,51.33,1.86,57.51,2.68v60.43H364.15c-28.12,0-33.48,13.3-33.48,32.9V240h67l-8.75,64H330.67V480h124.6A24.74,24.74,0,0,0,480,455.27V56.73A24.74,24.74,0,0,0,455.27,32Z" />
                  </svg>
                </button>
              </a>
              <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/paraisolandscaping?igshid=YmMyMTA2M2Y">
                <button className="mx-2 text-gray-700 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400" aria-label="Twitter">
                  <svg className="w-4 h-4 fill-current" viewBox="0 0 512 512">
                    <path d="M496,109.5a201.8,201.8,0,0,1-56.55,15.3,97.51,97.51,0,0,0,43.33-53.6,197.74,197.74,0,0,1-62.56,23.5A99.14,99.14,0,0,0,348.31,64c-54.42,0-98.46,43.4-98.46,96.9a93.21,93.21,0,0,0,2.54,22.1,280.7,280.7,0,0,1-203-101.3A95.69,95.69,0,0,0,36,130.4C36,164,53.53,193.7,80,211.1A97.5,97.5,0,0,1,35.22,199v1.2c0,47,34,86.1,79,95a100.76,100.76,0,0,1-25.94,3.4,94.38,94.38,0,0,1-18.51-1.8c12.51,38.5,48.92,66.5,92.05,67.3A199.59,199.59,0,0,1,39.5,405.6,203,203,0,0,1,16,404.2,278.68,278.68,0,0,0,166.74,448c181.36,0,280.44-147.7,280.44-275.8,0-4.2-.11-8.4-.31-12.5A198.48,198.48,0,0,0,496,109.5Z" />
                  </svg>
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className="mt-6 lg:mt-0 lg:flex-1">
          <div className="grid grid-cols-2 gap-6">
            <div>
              <h3 className="text-gray-700 uppercase dark:text-white break-words">Site links</h3>
              <FooterLinks text={"home"} location={location} />
              {/* <FooterLinks text={"about"} location={location} />
              <FooterLinks text={"services"} location={location} />
              <FooterLinks text={"gallery"} location={location} />
              <FooterLinks text={"contact us"} location={location} /> */}
            </div>

            <div>
              <h3 className="text-gray-700 uppercase dark:text-white break-words">Find Us</h3>
              <button className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline break-words">Phone: {companyNumber}</button>
              <a href="mailto:paraisolandscaping.pnw@gmail.com" className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline break-words">Email: {companyEmail}</a>
              <p className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline break-words">Mon-Sat: 8AM - 5PM</p>
            </div>
          </div>
        </div>
      </div>

      <hr className="h-px my-6 bg-gray-300 border-none dark:bg-gray-700" />

      <div>
        <p className="text-center text-gray-800 dark:text-white break-words">© {title} 2022 - All rights reserved</p>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  title: PropTypes.string,
  companyNumber: PropTypes.string,
  companyEmail: PropTypes.string,
  location: PropTypes.any
};

Footer.defaultProps = {
  title: "",
  companyNumber: "",
  companyEmail: "",
  location: {}
};

export default Footer;
