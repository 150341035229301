import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const titleLinks = ({ text, location, className }) => {
  const link = text.replace(/\s/g, "_");
  const homeLink = link.includes("Paraiso_Landscaping") ? "" : link;
  const active = location.pathname === "/";
  const color = active ? "text-green-500 " : "text-gray-800 dark:text-white";

  return (
    <Link to={`/${homeLink}`} className={`text-xl font-bold hover:text-green-700 ${color} ${className} ${active ? "border-green-500 border-b-4 break-words" : "break-words"}`}>
      <button>
        {text.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())}
      </button>
    </Link>
  );
};

titleLinks.propTypes = {
  text: PropTypes.string,
  location: PropTypes.any,
  className: PropTypes.string
};

titleLinks.defaultProps = {
  text: "",
  location: {},
  className: ""
};

export default titleLinks;
