import * as React from "react";
import PropTypes from "prop-types";
// import { useStaticQuery, graphql } from "gatsby";
// import { slide as Menu } from "react-burger-menu";
// import { GatsbyImage } from "gatsby-plugin-image";
import { HeaderLinks, TitleLinks, } from "../links";

const Menu = ({ title, location, links = [] }) => {
  // const { allFile: { edges } } = useStaticQuery(graphql`
  // query {
  //       allFile(
  //         filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "logo"}}
  //       ) {
  //         edges {
  //           node {
  //             childImageSharp {
  //               gatsbyImageData(layout: FIXED)
  //             }
  //           }
  //         }
  //       }
  //     }
  // `);
  const headerLinks = links.length > 0 && links.map((link, idx) => (<HeaderLinks text={link} location={location} key={link + idx} />))
  return (
    <header className="bg-white dark:bg-gray-800 shadow-lg max-w-full w-full">
      <div className="mx-5 px-4" style={{ maxWidth: "100vw" }}>
        <div className="flex justify-between">
          {/* <div className={"flex justify-center items-center overflow-hidden"}>
            <GatsbyImage
              image={edges[0].node.childImageSharp.gatsbyImageData}
              objectFit={"contain"}
              className={"shrink max-h-72 md:max-h-96 shadow-md"}
              alt={"some description"}
            />
          </div> */}
          <div className="flex justify-center items-center space-x-7">
            <TitleLinks className={"py-4"} text={title} location={location} />
          </div>
          {/* <!-- Primary Navbar items --> */}
          <div className="md:flex items-end">
            {/* todo: hide links when showing mobile nav when future links are added in again */}
            {headerLinks.length > 0 && headerLinks}
          </div>
          {/* <div className="md:hidden flex md:flex-none justify-center items-center" >
            <Menu width={"350"} right>
              {headerLinks}
            </Menu>
          </div> */}
        </div>
      </div>
    </header>
  );
};

Menu.propTypes = {
  siteTitle: PropTypes.string,
  links: PropTypes.array,
  title: PropTypes.string,
  location: PropTypes.any
};

Menu.defaultProps = {
  siteTitle: "",
};

export default Menu;
