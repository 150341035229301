/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";
import Menu from "../menu";
import Footer from "../footer";

const Layout = ({ children, location, site }) => {
  return (
    <>
      <Menu {...site.siteMetadata} location={location} />
      <main>
        {children}
      </main>
      <Footer {...site.siteMetadata} location={location} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.any,
  site: PropTypes.any
};

export default Layout;
